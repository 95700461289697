import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SidenavService {
    opened: boolean;

    /**
     * Constructor
     */
    constructor() {
        this.opened = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle open/close the sidenav
     */
    toggleOpen(): void {
        this.opened = !this.opened;
    }
}
