import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { PublicSharedModule } from '../@public/shared.module';

/*
  Notice
  -------
  Be sure to import the Angular Material modules after
  Angular's BrowserModule, as the import order matters for NgModules
*/
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';

// Services

// Environments

// Angular Fire

/*
  loadChildren
  -------------
  Lazy loading speeds up our application load time by splitting it into multiple bundles,
  and loading them on demand. The router is designed to make lazy loading simple and easy.
*/
const appRoutes: Routes = [
  {
    path: '',
    loadChildren: './main/pages/pages.module#PagesModule'
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

    PublicSharedModule,

    // App Modules
    LayoutModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
