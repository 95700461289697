import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidenavService } from '../sidenav/sidenav.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.css']
})

export class SidenavComponent implements OnInit, OnDestroy {

    menu;

    constructor(
        private _sidenavService: SidenavService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.menu = [
            { icon: 'face', desc: 'About Me', link: 'about-me' },
            { icon: 'code', desc: 'Skills', link: 'skills' }
        ];
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidenavOpened(): void {
        this._sidenavService.toggleOpen();
    }
}
