import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatSidenavModule } from '@angular/material/sidenav';

import { ContentModule } from '../components/content/content.module';
import { SidenavModule } from '../components/sidenav/sidenav.module';
import { ToolbarModule } from '../components/toolbar/toolbar.module';

import { PortfolioLayoutComponent } from './portfolio.component';

@NgModule({
    declarations: [PortfolioLayoutComponent],
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        MatSidenavModule,
        ContentModule,
        SidenavModule,
        ToolbarModule,
    ],
    exports: [PortfolioLayoutComponent],
})
export class PortfolioLayoutModule { }
