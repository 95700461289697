import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { SidenavService } from '../components/sidenav/sidenav.service';

@Component({
    selector: 'app-portfolio-layout',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PortfolioLayoutComponent implements OnInit, OnDestroy {
    sideNav: SidenavService;

    /**
     * Constructor
     */
    constructor(
        private _sidenavService: SidenavService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.sideNav = this._sidenavService;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }
}
