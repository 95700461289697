import { NgModule } from '@angular/core';

import { PortfolioLayoutModule } from '../layout/portfolio/portfolio.module';

@NgModule({
    imports: [
        PortfolioLayoutModule,
    ],
    exports: [
        PortfolioLayoutModule,
    ],
})
export class LayoutModule { }
